@import '././../../../css-config/mixins.scss';
.project-tech{
  margin: 20px 0 ;
 padding:10px;
 background-color: white;
 
 @include md{
  margin: 40px 0 ;
 }
  // background-color: white;
  

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
      -webkit-transition: all .25s ease-in;
      -moz-transition: all .25s ease-in;
      -ms-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      transition: all .25s ease-in;
      opacity: .4;
      filter: alpha(opacity=40);
      position: absolute;
      z-index: 9;
      top: 10px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
   
      border-radius: 100%;
      background: rgb(21, 21, 21);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border: 0;
     
      font-size: 32px;
      cursor: pointer
  }

  .carousel .control-prev.control-arrow {
    left: 50px;
 
  
    
}

.carousel .control-next.control-arrow {
  left: 100px
}

.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}
 

  .project-part{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  justify-content: center;
    align-items: center;
  
    @include md{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 20px;
    }

  .project-left{
max-width: 400px;
color: rgba(0, 0, 0, 0.582);
font-family: Georgia, 'Times New Roman', Times, serif;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


background-color: rgb(255, 255, 255);
padding: 10px;
// height: auto;
  }

  .project-right{
    max-width: 500px;
    
    height: auto;
    position: relative;



  

    background-color: rgb(255, 255, 255);

   
  
    
    // height: auto;

    @include md{
      padding: 0;

      &:hover .overlay {
        height: 100%;
      }
    }

    img{
      height: 100%;
      width: 100%;
      display: block;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #0003ba34;
      overflow: hidden;
     width: 100%;
     height: 100%;
      transition: .5s ease;


      @include md{
        height: 0;
        width: 100%;
      }


      .text {
        color: white;
        font-size: 20px;
        position: absolute;
        overflow: hidden;
        top: 10%;
        left: 10%;
        font-weight: 900;
        transform: translate(-10%, -10%);
        -ms-transform: translate(-50%, -50%);
        white-space: wrap;
        line-height: 30px;
        padding: 0 10px;
      }

      .demo{
        white-space: nowrap; 
        color: white;
        font-size: 20px;
        position: absolute;
        overflow: hidden;
        top: 90%;
        left: 90%;
        cursor: pointer;
        font-weight: 900;
        transform: translate(-90%, -90%);
        // -ms-transform: translate(-50%, -50%);
        white-space: wrap;
        line-height: 30px;
        background-color: blue;
        padding: 8px 24px;
        border-bottom: 2px solid white;
        transition: 0.2s all;

        @include md{
          padding: 12px 16px;
        background-color: green;
        border: none;
        }
       

        &:hover{
          background-color: blue;
          padding: 8px 24px;
          border-bottom: 2px solid white;
        }
      }
      
    }
      }

    }
}