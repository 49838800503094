
@import '../../css-config/mixins.scss';
.indicates-main{
    background-color: white;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   right: 0;
   border-radius: 8px;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 174, 255, 0.313);
    color: rgba(0, 0, 0, 0.726);
    transition: all 0.5s ease;

    &.indicates-down{
     
   
       max-width: 300px;
       min-width: 200px;
       width: 100%;
       height: fit-content;
    //    padding: 10px;
       text-align: center;
        top: 55px;
       right: -40px;
         color: rgba(0, 0, 0, 0.731);
         border-radius: 20px;

         @include cd{
            max-width: 200px;
            min-width: 150px;
            width: 100%;
            font-size: medium;
         }

         .dn1{
            position: relative;
            padding: 20px;
            border-radius: 20px;

            p{
                margin-top: 10px;
         }

         .arrow{
            position: absolute;
            top: -10px;
            right: 70px;
           
            width: 0;
          height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top:  14px solid rgb(255, 255, 255);
            transform:rotate(180deg);
      
         }
 

       svg{
        position: absolute;
        top: 10px;
        right: 10px;
       

       }

    }

    }
   
}



