@import "../../../css-config/mixins.scss";
.contact-infi{
color: black;
    height: auto;
.main-contact {
    display: flex;
    justify-content: center;
    align-items: center;

    .contact {
        margin: 20px;
   

        .heading {
            padding: 20px 0;
            text-align: center;
            font-size: large;

            span {
                position: relative;
                top: 13px
            }
        }

        .content {


            margin: 50px;
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            align-items: center;
            gap: 60px;
            font-size: 18px;
            
            cursor: pointer;

            .sub-content {
                padding: 8px 31px;

             



               
               


            }

            #mail{
               
                    background-color: rgb(0, 174, 255) ;
                    border-radius: 20px;
        
             
            }
           


        }
       

        .page{
            display: flex;
            justify-content: center;
            justify-content: center;
            
        }

    }

   
}

}