 @import "../../../css-config/mixins.scss";

 .navbar {
     overflow: hidden;
  background-color: #f8f9fa; /* Light background */
  color: #343a40; /* Dark text color */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     display: none;

     @include md{
       
        display: flex;
        justify-content: space-around; /* Space elements evenly */
        padding: 0 20px; /* Add some padding for better spacing */
        align-items: center;
        overflow-x: hidden;
      
     }
   
    
   
     




     .dropdown {
         float: left;
         overflow: hidden;


         &:last-child{

            .dropdown-content{

              min-width: 120px;
            }
         }
      
      


         .dropbtn {
             font-size: 16px;
             border: none;
             outline: none;
            
             padding: 14px 23px;
             background-color: #f8f9fa;
             font-family: inherit;
             margin: 0;
             display: flex;
             justify-content: center;
             align-items: center;
             gap: 10px;
             border-radius: 6px;
             transition: all 0.6s;
             box-shadow: none;

          
         }

        
         &:hover .dropbtn {
            color: rgb(0, 174, 255); 
            background-color: #e9ecef86; 
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
             
         }

         .dropdown-content {
             display: none;
             position: absolute;
          background-color: white;
             min-width: 160px;
           
             animation: dropdownOpen 0.3s ease-in-out;
               box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
             z-index:99;

           
            
           
             

             a {
                float: none;
                transition: all 0.2s ease-in-out;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                text-align: left;
                display: flex;
                // justify-content: center;
                align-items: center;
                gap: 10px;
                // cursor: pointer;
                border-bottom: 1px solid rgba(0,0,0,0.5);
                font-weight: 600;
                color: rgb(0, 0, 0);
                pointer-events: visible; /* Disable pointer events to make it unclickable */
                text-decoration: none; /* Remove underline */
                cursor: pointer;

                &:last-child {
                    border-bottom: none; /* Remove border from last item */
                  }
                
                

                &:hover {
                    color:rgb(0, 174, 255); 
                    padding-left: 20px; 
                    background-color: #f8f9fa; 
                  }
            }
         }

         

         

         &:hover .dropdown-content {
            display: block;
      transform-origin: top;

     
             
           
        
         }
     }
 }

@keyframes dropdownOpen {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}