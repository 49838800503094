@import "../../../css-config/mixins.scss";
.side-menuv {
    overflow-y: scroll;

  
   

    .menu-listv {
     


            li {
                display: flex;
                list-style: none;
                padding: 14px 18px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: all 0.3s ease-in;
                
                
                
              

                svg {
                    margin-left: 10px;
                    font-size: 22px;
                }

                &:hover {
                    background-color: rgb(0, 174, 255);
                    color: rgb(255, 255, 255);
                    cursor: pointer;
                  
                    
                    


                }

            }

        
    }
}
