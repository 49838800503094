@import '../../css-config/mixins.scss';



.choose-main {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.411);
    z-index: 9999999;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    


.c-modal {


    .cio{
        position: absolute;
        right: 20px;
        cursor: pointer;
        top: 20px;
    }

    @include md{
max-width: 900px;
min-width: 300px;
width: 100%;
margin: 20px;
display: flex;
overflow: auto;
flex-direction: column;
align-items: center;
padding: 40px 20px;
transform: translateY(-400px);
// max-height: 650px;
// min-height: 300px;
// height: 100%;


    background-color: white;
    border-radius: 24px;
    animation: cm 0.4s forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    }

@include cd{
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    // justify-content: flex-end;
    padding: 40px 20px;
   height: 100vh;
  
   width: 100%;
   position: fixed;
   background-color: white;

}

.mv{

display: flex;
// background-color: aqua;
justify-content: center;
margin-bottom: 50px;
@include md{
display: none;

}




    img{
       
        width: 50%;
    }
}


    .title{
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 20px;

        
        

        @include cd{
            font-size: 24px;
            margin-bottom: 15px;
        }

 

    }

    p{
        color: rgba(0, 0, 0, 0.68);
        letter-spacing: 1px;
        margin-bottom: 70px;
        word-spacing: 1px;

        @include cd{
            margin-bottom: 40px;
        }

      
        
                }


                .looking{
                    display: flex;
                   justify-content: space-around;
           
                    width: 100%;
                 
                    row-gap: 30px;
               

                    @include cd{
                        flex-direction: column;
                    }
                   

                .lbo{
                    list-style: none;
                   position: relative;
                   
                  
                  width: 100%;
                //   display: flex;
                 
                    padding: 12px 20px;
                    border-radius: 20px;
                    letter-spacing: 2px;
                    word-spacing: 1px;
                    font-weight: 500;
                    cursor: pointer;
                   
                    border: 1px solid rgb(0, 174, 255);
                    animation: opn 0.4s ;
                    color: white;
                    background-color: rgb(0, 174, 255);
                    animation-delay: 0.3s;


                 @include cd{

                    &:nth-child(1){
                        background-color: transparent;
                        border: 1px solid rgb(0, 174, 255);
                        color: rgba(0, 0, 0, 0.699);
                    }
                 }

                    @include md{

                        width: 100%;
                        max-width: 300px;
                        min-width: 300px;

                        &:nth-child(1){
                            background-color: transparent;
                            border: 1px solid rgb(0, 174, 255);
                            color: rgba(0, 0, 0, 0.699);

                            &:hover{
                                border: 1px solid rgb(0, 174, 255);
                                color: rgb(255, 255, 255);
                                background-color:rgb(0, 174, 255);
                               
        
                            }
                        }

                        &:hover{
                            border: 1px solid rgb(0, 174, 255);
                            color: rgba(0, 0, 0, 0.699);
                            background-color: transparent;
                           
    
                        }


                    &:hover svg{
                        visibility: visible;
                       
                        transform: translate(-10%,-50%) scale(1);
                    }
                }

               

                  


                       
                        

                   

                    
                    

                    svg{
                        position: absolute;
                        top: 20%;
                        right: 10%;
                        transform: translate(-20%,-50%) scale(0);
                        visibility: hidden;
                        transition: all 0.3s;

                        @include cd{
                            visibility: visible;
                            top: 10%;
                        right: 10%;
                        transform: translate(-10%,-10%) scale(1);
                          
                        }
                    }

                    li{
                        
                      

                        @include cd{
                            font-size: 15px;
                            letter-spacing: 0.3;
                           
                        }
span{
 
    font-weight: bolder;
    line-height: 35px;
    font-size: 18px;
}
                    }


                    img{
                        width: 20%;
                        margin-bottom: 10px;

                        @include cd{
                            width: 20%;
                        }
                    }
                }

            }
}
}

@keyframes cm{
    0%{
        transform: translateY(-400px);
    }

    100%{
        transform: translateY(0px);
    }
}


@keyframes opn{
    0%{
        transform: scale(0.7);
    }

    50%{
        transform: scale(1.1);
    }

    100%{
        transform: scale(1);
    }
}
