@import "../../../../css-config/mixins.scss";








  .layout-s {
      max-width: calc(100% - 20px);
      margin: 20px auto;
      padding: 0 10px;
      display: flex;
      gap: 10px;
      justify-content: space-around;
     align-items: center;
     flex-wrap: wrap;

      @include md {
          max-width: 1200px;
          margin: 55px auto;
         
      }

      @include mdd {
              
        flex-wrap: nowrap; 
    }


          .left {
              width: 100%;
              background-color: rgba(0, 0, 0, 0.05);
              flex-shrink: 0;
              transform-style: preserve-3d;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
             

              @include mdd {
                  width: 500px;
                  height: 300px;
              }

              img {
                  width: 100%;
                  display: block;
                  height: 100%;
                  -webkit-user-drag: none;
              }


          }

          .right {
              display: flex;
              flex-direction: column;
              padding:10px;
              max-width: 500px;
    

            


              .price {
                  font-size: 20px;
                  line-height: 32px;
                  margin-bottom: 20px;
                  text-transform: uppercase;
                  word-spacing: 8px;
                  font-weight: 700;


                  &:after {
                      content: "";
                      display: block;
                      margin-top: 5px;
                      width: 50px;
                      height: 3px;
                      background-color: rgb(0, 174, 255);

                      @include md {
                          margin-top: 10px;
                      }
                  }



              }

              .desc {
                  text-indent: 50px;
                  text-align: justify;
                  font-size: 15px;
                  line-height: 20px;
                  margin-bottom: 20px;
                  color: #6b6b6b;

                  &::first-letter{
                    font-size: 24px;
                  }

                  @include md {
                      font-size: 16px;
                      line-height: 30px;
                  }

                  
              }









          }
      
  }


