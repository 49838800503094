@import '../../../css-config/mixins.scss';

.join-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 20px;
  align-items: center;

  @include m600 {
    padding: 10px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;

    span{
      font-size:medium;
      color: #333;
    }

    @include m600 {
      font-size: 20px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
 width: 400px;

 @include m600{
    width: 300px;
 }

 @include m400{
    width: 250px;
 }

 @include m350{
    width: 200px;
 }

  
.part{
    display: flex;
    gap: 10px;
}
    label {
      font-weight: bold;
      font-size: 14px;
    }

    input[type='text'],
    input[type='email'] {
      padding: 12px 16px; /* Increased padding */
      border-radius: 4px;
      border: 1px solid #ccc;
      font-size: 16px; /* Increased font size */
      transition: box-shadow 0.3s ease;

      &:focus {
        outline: 0;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }
    }

    .error {
      color: red;
      font-size: 12px;
    }
  }

  p{
    color: #333333b8;
    font-size: small;
    max-width: 400px;
   align-self: flex-start;
   width: 100%;
  }

  button {
    padding: 10px; /* Increased padding */
    width: 100%;
    max-width: 250px; /* Increased max-width */
    background-color: rgb(0, 136, 255);
    font-weight: 700;
    color: white;
    border: none;
    border:1px solid rgb(0, 136, 255);
    border-bottom: 5px solid rgb(0, 136, 255);;
    border-radius: 4px;
    font-size: 18px; /* Increased font size */
    cursor: pointer;
    transition: all 0.3s ease;
    transition: background-color 0.3s ease;

    &:hover{
        background-color: transparent;
        border:1px solid rgb(0, 136, 255);
        border-bottom: 5px solid rgb(0, 136, 255);;
        color: black;
    }

    @include m600 {
      width: 100%;
      font-size: 14px;
    }

    &:disabled {
      background-color: #aaa;
      cursor: not-allowed;
    }
  }
}
