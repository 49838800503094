@import "../../../../css-config/mixins.scss";


.sql-main {
   
    width: 100%;
    height: calc(100vh - 53px);
    display: flex;
    justify-content: flex-start;

    @include md{
        height: calc(100vh - 82px);
    }
    
    
    

    .sql-left {
        width: 100%;
        overflow-y: auto;
       
        animation: sql 0.5s ease-in-out;

        scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.292) white;    
    
    /* For WebKit browsers (e.g., Chrome, Safari) */
    .scrollable-text::-webkit-scrollbar {
        width: 8px;
    }
    
    .scrollable-text::-webkit-scrollbar-track {
        background: white;
    }
    
    .scrollable-text::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }

      
        @include md {
            width: calc(100% - 80%);
            animation: none;

        }


     

       



    }



    .sql-right {
        width: 100%;
        z-index: 2;
        overflow: auto;
        padding: 10px 20px;
        background-color: #dedede;
        position: relative;
        scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.292) white;    
    
    /* For WebKit browsers (e.g., Chrome, Safari) */
    .scrollable-text::-webkit-scrollbar {
        width: 8px;
    }
    
    .scrollable-text::-webkit-scrollbar-track {
        background: white;
    }
    
    .scrollable-text::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }


        
       
       

        @include md {
            width: 80%;
            padding: 20px 40px;

        }

        .menu-oppen{
            position: absolute;
            left: 50px;
            top: 70px;
            z-index: 999;
            background-color: white;
            color: black;
            padding: 8px 12px;
            width: fit-content;
            border-bottom: 3px solid rgb(12, 162, 243);
            position: fixed;

            @include md{
                display: none;
            }

           

          
        }

        .nesting-main{
            text-align: justify;
            letter-spacing: 1px;  
            line-height: 29px;           
            margin: 80px auto;
            word-spacing: 3px;
            color: rgba(0, 0, 0, 0.8);
            display: flex;  
            flex-direction: column;
            gap: 30px;

            &:nth-child(2){
                margin: 20px auto;
            }
           
           


            .nesting-title{
           
                letter-spacing: 2px;
                text-transform: capitalize;
                text-wrap: wrap;
                word-wrap: break-word;
                word-spacing: 6px;
                color: rgba(0, 0, 0, 0.813);
                font-weight: 600;
                line-height: 30px;
                font-size: 25px;

            

                @include cd{
                    font-size: 20px;
                }

        
    
                &:after {
                    content: "";
                    display: block;
                    margin-top: 5px;
                    width: 50px;
                    height: 3px;
                    background-color: rgb(0, 174, 255);
        
                }
           
            }

            .nesting-desc{
                border: 1px solid rgba(0, 0, 0, 0.326);
                background-color: white;
                padding: 20px 30px 20px 20px;
                font-weight: 500;
                font-family: monospace;
                line-height: 40px;
                position: relative;
                
                white-space: pre-wrap; 
                word-wrap: break-word;
                
              
                overflow-y: auto;
                max-height: 400px; 
                
              
                scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.292) white;            
            
            /* For WebKit browsers (e.g., Chrome, Safari) */
            .scrollable-text::-webkit-scrollbar {
                width: 8px;
            }
            
            .scrollable-text::-webkit-scrollbar-track {
                background: white;
            }
            
            .scrollable-text::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 10px;
            }

                svg{
                    position: sticky;
                    left:100%;
                    top: 10px;
                    cursor: pointer;
                    
                }


                
             }

             .run_btn{
              
                border: 0.5px solid rgba(255, 255, 255, 0.582);
                color: white;
                display: flex;
                font-size: 16px;
                padding: 4px 10px;
                cursor: pointer;
              align-self: flex-end;
                align-items: center;
                font-weight: 600;
                justify-content: center;
                text-transform: capitalize;
               width: 100px;
               background-color:rgb(12, 162, 243) ;

               transition: all 0.2s ease-in ;

               @include cd{
                width: fit-content;
               }

                &:hover{
                    color: rgb(0, 0, 0);
                    border: 0.5px solid rgba(0, 0, 0, 0.505);
                    background-color:rgb(255, 255, 255) ;
                }

               
             }


             
           

            img{
                width: 100%;
               
                
             
               
            }

         

          

          

            

        }
          
       
        

    }


   

    
   


}


@keyframes sql{
    0% {width: 0}
    100%{width: 100%;}
}
