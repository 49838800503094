@import '../../../../css-config/mixins.scss';

.grid-container {
  padding: 10px;
    flex-flow: wrap;
display: flex;
row-gap: 20px;
 justify-content: space-between;

 @include ss{
  justify-content: center;
  gap: 10px;
 }

 

    .grid-item {
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s;
      width: 300px;
      height: 300px;
      overflow: hidden;
      position: relative;

      &:hover .overlay{
        background-color: transparent;
       
      }

      &:hover img{
        transform: scale(1.2);
      }


      .overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.409);
      }

      @include ss{
        width: 150px;
        height: 150px;
      }

      
      @include ssss{
        width: 120px;
        height: 120px;
      }
    

      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in;

      }

   
    }
  }

