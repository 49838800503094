@import '../../../css-config/mixins.scss';

.webu-info {
    margin: 60px 0;
    display: flex;
    position: relative;
    justify-content: space-between;

    @include cdd{
        flex-direction: column-reverse;
        margin: 40px 20px;
        align-items: center;
    }



    .part1 {
        width: 60%;
       padding: 10px 0;

       @include cdd{
        width: 100%;
        margin: 40px 0;
       }



       .regtu{
       transition: all 0.3s ease-in-out;
        overflow: hidden;
        width: 100%;
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: center;
      position: fixed;
     
   
      left: 0;
      
       z-index: 9;
      
     

        button{
            
            background-color:rgb(0, 174, 255);
            color: white;
            border: none;
            padding: 10px;
            width: 200px;
             font-size: 16px;
             border-radius: 16px;
        }

        @include cd1{
            display: none;
        }
       }
      
     

         .oneb{
        
        height: 1px;
        background-color: rgba(0, 0, 0, 0.128);
        margin-bottom: 40px;

        @include cdd{
           display: none;
        }
        
       
       }

       .infoa{
       
        width: fit-content;
        padding: 10px 0;
        line-height: normal;
    margin-bottom: 40px;
    
    position: relative;
    border: none !important;

    @include cd{
        margin-bottom:  25px;
        font-size: 20px;
    }

   
 

    &::after {
        content: "";
        position: absolute;
        top: 45px;

       
        left: 0;
       
        width: 30%;
        height: 3px;
        background-color: rgb(0, 174, 255);
        transition: width 0.3s ease-in-out;

     
        }

      
        

}

        .p-type{
          
            display: flex;
          flex-flow: wrap;
            align-items: center;
            margin: 40px 0;
           
          
            gap: 40px;

            @include cdd{
                margin: 10px 0;
                gap: 30px;
                
                
            }
            @include cd{
                margin: 10px 0;
                gap: 20px;
                row-gap: 25px;
               
                
                
            }


            .first{

                display: flex;
                gap: 12px;
                align-items: center;
               
               width: 190px;
           
               
                
                @include cdd{
                   flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                }

                @include cd{
                    width: 100px;
                    height: 110px;
                  justify-content: flex-start;
                }


                &:nth-child(2){
                    svg{
                    
                        color: rgb(0, 51, 128);
                        font-size: 24px;
                        background-color: rgba(27, 61, 148, 0.114);
                        height: 40px;
                        border-radius: 6px;
                        width: 40px;
                        padding: 6px;

                        @include cdd{
                            width: 30px;
                            height: 30px;
                            padding: 3px;
                            font-size: 15px;
                        }
                    }
                }

                &:nth-child(3){
                    svg{
                    
                        color: rgb(0, 101, 241);
                        font-size: 24px;
                        background-color: rgba(12, 113, 236, 0.114);
                        height: 40px;
                        border-radius: 6px;
                        width: 40px;
                        padding: 6px;

                        @include cdd{
                            width: 30px;
                            height: 30px;
                            padding: 3px;
                            font-size: 15px;
                        }
                    }
                }

                &:nth-child(4){
                    svg{
                    
                        color: rgb(241, 169, 0);
                        font-size: 24px;
                        background-color:rgba(241, 241, 0, 0.151);
                        height: 40px;
                        border-radius: 6px;
                        width: 40px;
                        padding: 6px;

                        @include cdd{
                            width: 30px;
                            height: 30px;
                            padding: 3px;
                            font-size: 15px;
                        }
                    }
                }

                &:nth-child(5){
                    svg{
                    
                        color: rgb(241, 0, 0);
                        font-size: 24px;
                        background-color:rgba(241, 0, 0, 0.087);;
                        height: 40px;
                        border-radius: 6px;
                        width: 40px;
                        padding: 6px;

                        @include cdd{
                            width: 30px;
                            height: 30px;
                            padding: 3px;
                            font-size: 15px;
                        }
                    }
                }

                svg{
                    
                    color: green;
                    font-size: 24px;
                    background-color: rgba(0, 128, 0, 0.114);
                    height: 40px;
                    border-radius: 6px;
                    width: 40px;
                    padding: 6px;

                    @include cdd{
                        width: 30px;
                        height: 30px;
                        padding: 3px;
                        font-size: 15px;
                    }
                }

                .mode{

                    text-align:left;

                    @include cdd{
                        text-align: center;
                    }

                    p{
                        color: rgba(0, 0, 0, 0.5);
                        text-transform: capitalize;
                        font-size: 15px;
                        font-weight: 200;

                        @include cdd{
                            font-size: small;
                        }
                    }

                    h4{
                        font-weight: 500;
                        text-transform: capitalize;
                        font-size:14px;
                        line-height: 20px;
                        letter-spacing: 0.4px;

                        @include cdd{
                            font-size: small;
                        }
                    }
                }
            }
        }
    }

    .part2 {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
       padding: 10px 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.15);
       height: fit-content;
        border-radius: 16px;
        position: sticky;
        top: 30px;
        transition: all 0.3s ease;

        @include cdd{
            position: static;
            margin-bottom: 40px;
            max-width: 100%;
           
   
        }

        @include cd{
            position: static;
            margin-bottom: 0px;
        }

        li {
            font-size: 14px;
font-weight: 700;
            list-style: none;
            color: #333;
            margin:4px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.199);
            background-color: rgba(0, 174, 255, 0.05);
            padding: 8px;
            border-radius: 8px;
          
            transition: all 0.3s ease;

            &:nth-child(1){
               display: flex;
               border: none;
               background-color:rgb(0, 174, 255);
               color:white;
               transform: scaleX(1.1);
               flex-direction: column;
               margin-bottom: 20px;
               border-radius: 16px;
                   padding: 12px 24px;
                   border-bottom: 1px dashed rgb(0, 174, 255);


                   @include cdd{
                    transform: scaleX(1);
                   }

                   p{
                    font-size: 12px;
                    font-weight: 300;
                    word-spacing: 1px;
                   }


                span{
                    
                    font-weight: bolder;
                    
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      margin-bottom: 6px;
                    
                     
               
                     
                      font-size: 18px;

                      svg{
                        transform: scale(1.2);
                        color: rgb(255, 255, 255);
                        
                    }
                }


              

            }


          


        }

        button{
            margin:20px auto;
            width: 80%;
            padding: 12px 0;
            background-color: white;
            background-color: rgba(0, 174, 255, 0.783);
            border-radius: 16px;
            color: rgb(0, 0, 0);
           outline-offset: 4px;
           font-weight: bold;
           outline: none;
           font-size: 16px;
           border: none;
           animation: regb 2s ease-in-out infinite;
           
            cursor: pointer;
          

            @include md{

            &:hover{
                background-color: rgb(0, 174, 255);

                outline: 2px dashed rgb(0, 174, 255);

  color: #fff;
  animation: none;
             
            }

        }

      
            

        }
    }
    
 
   
    

    
  
    
}


@keyframes regb{
    0%{box-shadow: 0 0 0 0px #3b83f65f;}
    100%{box-shadow: 0 0 0 5px #3b83f65f;}
}