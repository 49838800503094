@import "../../css-config/mixins.scss";

// Loader container styles
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

// When the loader is active (loading class added)
.loader-container.loading {
  opacity: 1;
  visibility: visible;
}

// Logo loader styles
.logo-loader {
  width: 40%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(0, 174, 255);
  transform: translate(-50%, -50%) scale(0);
  background-color: rgb(0, 174, 255);
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: grow 0.7s infinite;

  @include md {
    width: 15%;
  }

  img {
    width: 70%;
    height: auto;
  }
}

// Animation for scaling up with fade-in effect
@keyframes grow {
  0% {
    transform: translate(-50%, -50%) scale(0); /* Start from 0 scale */
    opacity: 0; /* Invisible at the start */
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* Scale to full size */
    opacity: 1; /* Fully visible */
  }
}

