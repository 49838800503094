@import '../../../../css-config/mixins.scss';
.Indus-main {
    text-align: center;
    padding: 20px;
    
    .Indus-heading {
      h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        line-height: 50px;
      }
    }
  
    .Indus-desc {
      font-size: 1.2rem;
      margin-bottom: 50px;
      color: #555;
    }
  
    .Indus-element {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;

      @include payment{
        gap: 10px;
    }
  
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 10px;
        width: 150px;
        color: #333;
        background-color: #f9f9f9;
        transition:all 0.3s ease-in;

        @include payment{
            width: 110px;
        }
  
        &:hover {
          background-color: rgb(0, 174, 255);
          color: white;
        }
  
        svg {
          font-size: 2rem;
         
          margin-bottom: 10px;
        }
  
        h3 {
          font-size: 1rem;
        }
      }
    }
  }
  