
@import "../../css-config/mixins.scss";
.NotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    gap: 20px;
    

    @include md{
        gap: 50px;
    }
  
    h1 {
      font-size: 2rem;
     
      color: #e74c3c; // Red color

      @include md{
        font-size: 4rem;

      }
    }
  
    p {
      font-size: 1rem;
      line-height: 40px;
      @include md{
        font-size: 1.5rem;
      }
     
    }
  
    a {
      display: inline-block;
      padding: 0.5rem 1rem;
      background-color: #3498db; // Blue color
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #2980b9; // Darker blue on hover
      }
    }
  }