// Mail.scss
.attenp{
    padding: 10px;
    
    .atten-form {
      max-width: 400px;
      margin: 40px auto;
      padding: 20px;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
      border-radius: 8px;
    
      form {
        display: flex;
        flex-direction: column;

        h1{
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 2px;
          padding: 10px 0;
        }
    
        .MuiTextField-root {
          margin-bottom: 16px;
        }
    
        .SM {
          align-self: flex-end;
         padding: 2px 6px;
          
        }
      }
    }
    }
    
    @media (max-width: 600px) {
      .atten-form {
        padding: 10px;
        margin:40px auto ;
       
       
       
    
       
      }
    }
    