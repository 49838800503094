@import "../../css-config/mixins.scss";


.model-main{
 
 height: 100%;
 width: 100%;
 z-index: 999;
 position: fixed;
 top: 0;
 left: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 

 



  
.opac-layer{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
 

}

.model-content{
  background-color: aliceblue;
  position: fixed;
  top: 0;
  z-index: 999;
  min-width:100%;
  min-height: 100%;
  padding: 30px 10px;
  animation: openmodel .5s ;
  
  
 
  
@include md{
  min-width: 700px;
  min-height: fit-content;
  padding: 30px 40px;
  top: auto;
  

}


  // animation: openmodel 2s;

  .model-heading{
    text-align: center;
    line-height: 30px;
    font-size: 32px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.575);
    padding-bottom: 20px;
    margin-top: auto;
   
  }

  .model-close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 30px;
    font-size: 30px;
    cursor: pointer;
    transition: transform .5s;
   

    &:hover{
      transform:rotate(180deg);
      font-size: 33px;
      opacity: 0.5;
    }
  }

  .model-pdf{
    padding: 10px 0;
    
    display: grid;
    
    grid-template-columns: auto auto;
    // margin: auto 0;

    gap: 20px;

    @include md{
      grid-template-columns: auto auto auto;
      gap: 40px;

    }

    a{
      margin: 0 auto;
      text-decoration: underline;
      cursor: pointer;

      &:hover{
        color: rgb(0, 115, 255);
      }
    }

    // justify-content: center;
    // align-items: center;

  }
}

}

@keyframes openmodel{
  0%{transform: scale(0);}
  100%{transform:scale(1)}
}



// @keyframes closemodel {
//   from {top:-300px; opacity:0}
//   to {top:0; opacity:1}
// }




