#terms-of-service {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
  
    .card {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     width: 90%;
      margin: 0 auto;
  
      .primary-heading {
        font-size: 2rem;
        margin-bottom: 15px;
        color: #333;
      }
  
      .section-heading {
        font-size: 1.5rem;
        margin-top: 25px;
        margin-bottom: 10px;
        color: #555;
      }
  
      .paragraph {
        font-size: 1rem;
        margin-bottom: 15px;
        color: #666;
      }
    }
  
    @media (max-width: 768px) {
      padding: 15px;
  
      .card {
        padding: 15px;
        width: 98%;
  
        .primary-heading {
          font-size: 1.8rem;
        }
  
        .section-heading {
          font-size: 1.3rem;
        }
  
        .paragraph {
          font-size: 0.95rem;
        }
      }
    }
  
    @media (max-width: 480px) {
      padding: 10px;
  
      .card {
        padding: 10px;
  
        .primary-heading {
          font-size: 1.5rem;
        }
  
        .section-heading {
          font-size: 1.2rem;
        }
  
        .paragraph {
          font-size: 0.9rem;
        }
      }
    }
  }
  