@import "../../../css-config/mixins.scss";

.project-main {
  margin: 40px auto;
  max-width: 1200px;

  @include cd {
    margin: 30px 20px;
  }

  .title-project {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 40px;
    text-transform: uppercase;
    word-spacing: 8px;
    font-weight: 700;

    &:after {
      content: "";
      display: block;
      margin-top: 5px;
      width: 50px;
      height: 3px;
      background-color: rgb(0, 174, 255);

      @include md {
        margin-top: 10px;
      }
    }
  }

  .containerm {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .card-main {
      width: 100%;
     
      background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      .card {
        transition: 0.3s;
        background-color: white;
        // border: 1px solid rgba(0, 0, 0, 0.1);
        // border-radius: 12px;
        // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        cursor: pointer;

        &:hover .overlay {
          height: 100%;
        }

        img {
          width: 100%;
          height: auto;
          padding: 10px;
          object-fit: cover;
          border-radius: 12px;
          -webkit-user-drag: none;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 12px;
          background-color: rgba(0, 0, 0, 0.5);
          overflow: hidden;
          width: 100%;
          height: 0;
          transition: 0.5s ease;

          @include md {
            height: 0;
          }

          .text {
            color: white;
            font-size: 18px;
            position: absolute;
            top: 20%;
          
            padding: 0 20px;
            
            text-align: center;
            transform: translateY(-20%);
           
            font-weight: 900;
           
            line-height: 30px;
            
          }

          .demo {
            color: black;
            font-size: 16px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-weight: 700;
            background-color: white;
            padding: 8px 24px;
            border-radius: 5px;
            border: 2px solid rgb(0, 174, 255);
            transition: 0.3s all;

            &:hover {
              background-color: rgb(0, 174, 255);
              color: white;
              border-color: white;
            }
          }
        }
      }

      .container {
        padding: 16px;
        // background-color: white;
        // border-radius: 12px;
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        h4 {
          line-height: 30px;
          white-space: pre-wrap;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
        }

        p {
          line-height: 24px;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
