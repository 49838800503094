@import '../../../../css-config/mixins.scss';


   

   .certyo{

    h2 {
       
        width: fit-content;
        padding: 10px 0;
       
    margin-bottom: 30px;
    position: relative;
    line-height: normal;
    border: none !important;
 
    @include cd{
      
        font-size: 20px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 45px;
       
        left: 0;
       
        width: 30%;
        height: 3px;
        background-color: rgb(0, 174, 255);
        transition: width 0.3s ease-in-out;

       
        }

      
        

}
    
    .certificate-box {

        display: flex;
        justify-content: space-around;
        align-items: center;
      flex-flow: wrap;
     
      margin: 80px 0px;

    


        @include me{

            row-gap: 40px;
        }
       

        &.certiok{

            display: flex;
          justify-content: flex-start;
            align-items: center;
          flex-flow: wrap;
         gap: 20px;
          margin: 10px 0px;


          @include cdd{
           justify-content: center;
          }




    


   

        
    
    
         
            img {
                box-shadow: 0 4px 8px rgba(0, 174, 255, 0.3), 0 6px 12px rgba(0, 174, 255, 0.2);
        width: 45%;
        border-radius: 12px;
        height: auto;

        @include cd{
            width: 90%;
            margin: 0 auto;
        }

             

             
            }

        

        .certificate-content {

            @include cd{
                margin: 0 auto;
            };
 

            li {
               
                gap: 9px;
                display: flex;
                align-items: center;
                font-size: 1.1em;
                list-style: none;
                color: #333;

                @include cd{
                    font-size: 1.02em;
                }

              

                svg {
                    color: rgb(0, 174, 255);
                }
            }
        }
        }
       


     

            img {
                box-shadow: 5px 5px 5px 5px lightblue;
                height: 200px;
                width: auto;

              
                @include s{
                   height: 300px;
                   width: auto;
                }

                @include md{
                    height: 400px;
                    width: auto;
                }
            }

        

        .certificate-content {


            li {
                margin: 12px;
                gap: 9px;
                display: flex;
                align-items: center;
                font-size: 18px;
                list-style: none;

                @include s{
                    font-size: 20px;
                }

                svg {
                    color: rgb(0, 174, 255);
                }
            }
        }

    }

}
