@import '../../../css-config/mixins.scss';

.webbk{
    margin: 10px 0;
    height: 350px;
   display: flex;
 
   background-size: 100%;
   color: rgb(255, 255, 255);
   justify-content: flex-start;
   align-items: flex-end;
   background-color: rgba(0, 0, 0, 0.269);
    border-radius: 25px;
    background: linear-gradient(rgba(0, 0, 0, 0.055), rgba(0, 0, 0, 0.696)), url('../../../assets/banner-web.jpg');
  

    @include cdd{
      margin: 0;
        height: 250px;
      
        background: linear-gradient(rgba(0, 0, 0, 0.055), rgba(0, 0, 0, 0.715)), url('../../../assets/banner-webm.jpg');
      
      
       border-radius: 0;
    }


    h1{
      
      width: 40%;
      font-size: 46px;
       
        line-height: normal;
        padding: 20px;
       
      

        @include cdd{
           
            font-size: xx-large;
           width: 100%;
            line-height: normal;
        }
    }
   
  
}