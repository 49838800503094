@import "../../../css-config/mixins.scss";

.services{
    padding: 20px 0;
    margin: 40px 0;
    font-family: 'Montserrat', sans-serif;
    color: #767676;
    font-weight: 400;
    box-sizing: border-box;


.container{
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;


 h2{
    font-family: 'Montserrat', sans-serif;
    position: relative;
    font-size: 27px;
    line-height: 1.4;
    text-align: center;
    font-weight: 700;
    color: #343434ad;
    margin-bottom: 45px;

    @include md{
        
            font-size: 30px;
            margin-bottom: 60px;
      
    }

   
}



.cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: space-between;
    row-gap: 60px;
    
    @include md{

        grid-template-columns: repeat(3, 1fr);
       

    }

    @include cardmd{
        grid-template-columns: repeat(1,1fr);
      

    }


    .card{
        width: 100%;
        text-align: center;
        margin-bottom: 45px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;


       


        @include md{
            margin-bottom: 0;
         max-width: 300px;
          
            
            transition: width 1s;
            

          
        
         
                  
              
        

        }


        svg{

            font-size: 50px;
    color: rgb(0, 174, 255);
    margin-bottom: 10px;

    @include cardmd{
        font-size: 70px;
    }
   

        }


        h3{
            font-size: 15px;
            line-height: 1.4;
            font-weight: 700;
            color: #343434;
            margin-bottom: 20px;
        }
    }















 
   
}


}
}

