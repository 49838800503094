@import '../../../../css-config/mixins.scss';

.domainok {
    max-width: 1200px;
    margin: 70px auto;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

    
    @include xxs {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        margin: 40px 15px;
        gap: 20px;
    }

    .card {
   
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 174, 255, 0.313);
        width: 100%;
        border-radius: 8px;
      
        transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

     

            display: flex;
            

            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            text-align: center;
            color: rgba(0, 0, 0, 0.824);
             padding: 20px 8px;
            font-weight: bold;
            // border: 1px solid rgba(0, 0, 0, 0.135);

            &:hover{
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);

            }

          


       
        

      

   

        .card-title {
            font-size: 1.2em; margin: 0;
            border-bottom: 1px dashed rgb(0, 174, 255);
          width: 70%;
          line-height: normal;
           height: 80px;
            // text-shadow: 0 0 1px black;
            margin-bottom: 15px;

            @include cd{
                font-size: 1em;
                height: 100px;
            }
           

        }


        button{
            width: 80%;
            
            padding: 8px;
            font-size: 16px;
            cursor: pointer;
            background-color: rgba(0, 174, 255, 0.753);
           font-weight: bold;
            border-radius: 8px;
            justify-self: flex-end;
            color: white;
            border: none;
            
         &:hover{
            background-color: rgb(0, 174, 255);
            
            color: white;
         }
        }

     

    
    }
}
