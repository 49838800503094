@import "../../css-config/mixins.scss";
footer{
   
   width: 100%;
//    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.7),rgba(120, 16, 255, 0.064),rgba(29, 21, 187, 0.105));
   .footer-content{
       padding: 50px 20px;
       max-width: 1200px;
       margin: 0 auto;
       display: flex;
       flex-flow: wrap;
       gap: 20px;

       @include md{
           justify-content: space-between;
           padding: 50px 0;
       }

       .col{
           max-width: 300px;

         

           &:nth-child(3){
            span{
                &.text{
                    cursor: default;
                }
            }
           }
           a{
            text-decoration: none;
           }
          

           .tmitless{
            text-align: left;
               margin-bottom: 20px;
               font-size: 20px;
               border-bottom: 1px solid rgba(128, 128, 128, 0.598);
               padding-bottom: 10px;
            
            //    border-width: 3px;
              
           }

           .text{
               color: rgba(0,0,0,0.5);
               font-size: 14px;
               line-height: 30px;
               cursor: pointer;

               h1{
                color: rgba(0,0,0,0.5);
                font-size: 14px;
               }
              
               
              
           }

           .c-item{
               display: flex;
               margin-bottom: 10px;
               align-items: center;
               cursor: default !important;
               svg{
                   flex-shrink: 0;
                   font-size: 14px;
                   margin-right: 10px;
                   margin-top: 4px;
                   color: rgba(0,0,0,0.5);

               }

               .text{
                // cursor: pointer;

                a{
                    text-decoration: none;
                    color:rgba(0,0,0,0.5) ;
                }
               }
           }

           span{
               &.text{
                   display: block;
                   margin-bottom: 10px;
                   cursor: pointer;
                   &:hover{
                    text-decoration: underline;
                   }
               }
           }
           
       }

   }

   .bottom-bar{
       border-top: 1px solid  rgba(0,0,0,0.5);

       .bottom-bar-content{
           padding: 20px;
           display: flex;
           align-items: center;
           flex-direction: column;
           text-align: center;
           gap: 10px;
           @include md{
               padding: 0;
               height: 60px;
               max-width: 1200px;
               margin: 0 auto;
               flex-direction: row;
               justify-content: space-between;
               text-align: left;
           }

           .text{
               font-size: 12px;
               color:  rgba(0,0,0,0.5);
            //    cursor: pointer;
           }

           img{
            @include ssss{
                width: 100px;
            }
           }
       }
   }
}