@import "../../../../css-config/mixins.scss";


    .page2-location{
        height: 400px;
        box-shadow: 0 0px 3px 0 rgb(0, 174, 255) , 0 3px 10px 0 rgb(0, 174, 255) ;
        padding: 30px;
        width: 250px;
        border-radius: 10px;

        @include s{
            width: 400px;
          
        }
        @include xxxs{
            width: 350px;
          
        }

        @include md{
            width: 650px;
        }

        @include ml{
            width: 850px;
        }
    }
