@import "../../../../css-config/mixins.scss";


.single-product-main-content {
    margin: 40px 0;
    

    @include md {
        margin: 55px 0;
    }





    .layout {
        max-width: calc(100% - 20px);
        margin: 0 auto;
        padding: 0 10px;

        @include md {
            max-width: 1200px;
           
        }


        .single-product-page {
            margin-top: 20px;
            display: flex;
         

            align-items: center;
           flex-wrap: wrap;


            @include mdd {
                
                margin-top: 80px;
                flex-wrap: nowrap;
            }

            .left {
                width: 100%;
                // background-color: rgba(0, 0, 0, 0.05);
                flex-shrink: 0;
                transform-style: preserve-3d;
                // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                // z-index: 999;
               

                // box-shadow: 1px 1px 1px 1px;
                @include md {
                    width: 500px;
                    height: 300px;
                }

                img {
                    width: 100%;
                    display: block;
                    height: 100%;
                }


            }

            .right {
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                // z-index: 999;

                @include md {
                    padding: 0 55px;

                    // margin-top: 90px;

                }


                .price {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    word-spacing: 8px;
                    font-weight: 700;


                    &:after {
                        content: "";
                        display: block;
                        margin-top: 5px;
                        width: 50px;
                        height: 3px;
                        background-color: rgb(0, 174, 255);

                        @include md {
                            margin-top: 10px;
                        }
                    }



                }

                .desc {
                    text-indent: 50px;
                    text-align: justify;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 20px;
                    color: #6b6b6b;

                    @include md {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }









            }
        }
    }
}

