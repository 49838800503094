@import "../../../css-config/mixins.scss";
$primary-color: rgb(0, 174, 255);
$secondary-color: black;

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $primary-color;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 2px;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.5s ease;
  z-index: 9999;

  .nav-item {
    text-decoration: none;
    color: white;
    padding: 2px 8px;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    &:nth-child(2) {
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: white;
          bottom: 3px;
          left: 0;
          transform-origin: bottom right;
          transform: scaleX(0);
          animation: underlineAnimation 1s infinite; // Apply the animation
        }
  
        @keyframes underlineAnimation {
          0%, 100% {
            transform: scaleX(0); // Start and end with no underline
          }
          50% {
            transform: scaleX(1); // Underline at 50%
          }
        }
  
        &:hover::after {
          animation: none; // Stop the animation on hover
          transform: scaleX(1); // Expand the line on hover
        }
      }
  


    @include md{

     
        &:hover {
            color: $primary-color;
            background-color: white;
          }

    }
   

    &.active {
      font-weight: bold;
      color: $secondary-color;
      background-color: white;
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
