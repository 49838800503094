.confirmation-page {
  background-color: #f9f9f9;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 30px auto;
  padding: 40px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;

  span {
    background-color: #28a745;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    svg {
      color: white;
      font-size: 60px;
    }
  }

  .con-text {
    margin-top: 20px;
    animation: fadeInUp 0.5s ease-in-out;

    h1 {
      font-size: 2.5em;
      color: #333;
      margin-bottom: 15px;
      line-height: 50px;
    }

    p {
      font-size: 1.2em;
      color: #555;
      line-height: 1.6;
      margin: 10px 0;

      &:first-of-type {
        font-weight: bold;
        color: #28a745;
      }
    }

    button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 12px 20px;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 20px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
