@import '../../../../../css-config/mixins.scss';

    .content-dynamic {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      padding: 1rem;
      flex-direction: column-reverse;
      align-items: center;
      row-gap: 20px;
      justify-content: space-between;
  
    @include md {
        flex-direction: row;
        padding: 1rem 10px;
      }
  
      .text-content {
        width: 100%;
        text-align: center;
  
        @include md {
          width: 50%;
        }
  
        .title {
          font-size: 1.80rem;
          font-weight: bold;
          color: #2d3748;
          line-height: 1.25;
          margin-bottom: 1rem;
  
          @include md {
            font-size: 3.75rem;
          }
        }
  
        .subtitle {
          font-size: 1.125rem;
          color: #718096;
          margin-bottom: 2rem;
  
          @include md {
            font-size: 1.25rem;
          }
        }
  
        .enroll-button {
          background-color: black;
          color: white;
          border: none;
          cursor: pointer;
          font-weight: bold;
          padding: 0.75rem 1.5rem;
          border-radius: 9999px;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          transition: background-color 0.3s ease-in-out;
  
          &:hover {
            background-color: #4299e1;
          }
        }
      }
  
      .image-content {
        width: 90%;
        height: 250px;
        
        // margin-top: 2rem;
  
        @include md {
          height: 420px;
          width: 50%;
          margin-top: 0;
          margin-left: 3rem;
        }
  
        img {
          border-radius: 0.5rem;
         width: 100%;
         height: 100%;
        }
      }
    }
  
  