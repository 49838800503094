@import "../../../css-config/mixins.scss";
.main-service-page {
    flex-flow: wrap;
    margin: 60px 0px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @include md{
        padding: 20px;
        gap: 30px;
    }

    .main-page {
        padding: 2px 17px;
        width: 210px;
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-direction: column;
        
        border: 1px solid black;
       


        @include md{
          
            &:hover .page-image{
                border: 3px solid rgba(0, 174, 255, 0.653);
               
               
            }
        }
       

        @include  cardf{
            width: 300px;
        }



        .page-image {

            position: relative;
            border-radius: 100%;
           transition: all 0.2s ease-in;
            height: 122px;
            width: 122px;
            border: 3px solid rgba(0, 174, 255, 0.653);

            @include md{
                border: 2px dashed black;
            }

            img {
                top: 18px;
                left: 18px;
                position: absolute;
                height: 80px;
                width: 80px;
                -webkit-user-drag: none;
            }
           
        }

        p {
            text-align: justify;
          
        }
    }
}