@import '../../../css-config/mixins.scss';

.sp-me{
    
    margin: 20px auto;
    padding: 30px 0;

    @include md{
        padding: 10px 0;
    }

    h2{
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
        animation: float 6s ease-in-out infinite;
    }

.sp-main {
    display: grid;
    grid-template-columns: auto auto auto;

   row-gap: 40px;
    justify-content: space-around;
    align-items: center;
    padding: 20px 10px;

    .bub {
        position: relative;
        width: 80px;
        height: 80px;

      @include payment{
        width: 40px;
        height: 40px;
      }
      
        .bubble {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: radial-gradient(circle at 50% 50%, rgba(240, 245, 255, 0.7), rgba(43, 130, 255, 0.4) 60%, rgba(240, 245, 255, 0) 70%);
          box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.3), 0 0 15px rgba(43, 130, 255, 0.6);
          animation: float 6s ease-in-out infinite;
      
          svg {
            font-size: 44px;
            color: rgb(0, 0, 0);

            @include payment{
               font-size: 24px;
              }
          }
      
          &::before, 
          &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
          }
      
          &::before {
            width: 50%;
            height: 50%;
            top: 20%;
            left: 25%;
            background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
            filter: blur(1px);
          }
      
          &::after {
            width: 30%;
            height: 30%;
            bottom: 15%;
            right: 15%;
            background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
            filter: blur(1px);
          }
        }
      }
      
      @keyframes float {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
      }
      

 
}
}
