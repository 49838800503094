// AnimatedParagraph.scss
.animated-paragraph-container {
    text-align: center;
    margin: 50px auto;

  h1{
    font-size: 34px;
    line-height: 30px;
    margin-bottom: 20px;
    color: rgba(0,0,0,0.7);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-in-out forwards;
  }
  .animated-paragraph {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
    color: rgba(0,0,0,0.7);

    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-in-out forwards;
  
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .animated-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.808);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-in-out 0.5s forwards;
  
    &:hover {
      background-color: rgb(0, 174, 255);
    }
  
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

}
  