@import "../../../css-config/mixins.scss";

.Main-info{
    margin: 80px auto;
   
    max-width: 1200px;

    @include cd{
        margin: 60px 20px;
    }
  
  
    .Info-pages{
        display: grid;
    gap: 30px;
    color: rgba(0, 0, 0, 0.719);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
       

    .desc-box{
       
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        text-align: center;
       width: 100%;
      
        padding: 20px 10px;
        background-color: transparent;
        transition: 0.7s ease-in-out;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 174, 255, 0.313);
            border-radius: 30px;
       

     


      
        
     

    

        svg{
            font-size: 50px;
            
        }
        p{
            transition: 0.7s ease-in-out;
            text-align: center;
        color: rgb(0, 174, 255);
            width: 90%;

           
        }
    }
        
    }
  

}