@import "../../../css-config/mixins.scss";

.newsletter-section{
    
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.185);
    margin: 40px auto;
    padding: 40px 0;
  
    display: flex;
    align-items: center;

    @include cd{
        margin: 10px auto;
    }

    .newsletter-content{
        width: fit-content;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        text-align: center;
   

    .small-text{
        margin-bottom: 15px;
        text-transform: uppercase;
        color: rgba(0,0,0,0.5);
    }

    .big-text{
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 30px;
        @include md {
            font-size: 28px;
            
        }
        @include ssss {
            font-size: 16px;
            
        }
    }

    .form{
        display: flex;
        gap: 5px;
        flex-flow: wrap;
        margin-bottom: 10px;

        .form-o{

            
            input{
                width: 200px;
                height: 40px;
                border-radius: 0;
                border: 1px solid rgba(0,0,0,0.2);
                padding: 0 12px;
                outline: 0;
                @include md {
                     width: 250px;
                }
                @include ssss {
                    width: 150px;
               }
            }
        }
      
        button{
            outline: 0;
            border: 0;
            height: 40px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16px;
            color: white;
            background-color: #000000;
            border-bottom: 3px solid #000000;
            @include md {
                width: 120px;
            }
        }
    }
    .text{
        margin-bottom: 20px;
        font-size: 14px;
        color: rgba(0,0,0,0.5);
    }

    .social-icons{
        display: flex;
        gap:10px;
        .icon{
            width: 40px;
            height: 40px;
            background-color: rgba(0,0,0,0.8);
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease-out;
            cursor: pointer;
         
            
        }

         svg:hover{
            transform: scale(1.7);
            transition: 0.2s;
            
            
        }
    }
}
}