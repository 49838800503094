@import "../../../css-config/mixins.scss";

.collage-main{
    padding:20px;
  
   
 &.collage-ok{
    padding: 10px 0;
    
      }

      h2 {
       
        width: fit-content;
        padding: 10px 0;
    margin-bottom: 40px;
    line-height: normal;
    
    position: relative;
    border: none !important;

    @include cd{
        margin-bottom: 30px;
        font-size: 20px;
    }

   
 

    &::after {
        content: "";
        position: absolute;
        top: 45px;

       
        left: 0;
       
        width: 30%;
        height: 3px;
        background-color: rgb(0, 174, 255);
        transition: width 0.3s ease-in-out;

    
        }

      
        

}
 
    .react-multiple-carousel__arrow {
        position: absolute;
        outline: 0;
        transition: all .5s;
        border-radius: 35px;
        z-index: 1;
        border: 0;
        background: transparent;
        min-width: 43px;
        min-height: 43px;
        opacity: 1; 
       
        cursor: pointer
    }

    .react-multiple-carousel__arrow::before {
        font-size: 30px;
        color: #000000;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative
    }

    .react-multiple-carousel__arrow--left {
        left: -7px
    }
    
    
    
    .react-multiple-carousel__arrow--right {
        right: -7px
    }

    .slider-student{
        text-align: center;
        padding-bottom: 30px;
        font-size: 17px;
        color: rgba(0, 0, 0, 0.5);
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

        @include md{
            padding-bottom: 40px;
            font-size: 28px;
        }
    }

    .collage-contain{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4px;



        .col-img{
            height: 80px;
            width: 80px;
            -webkit-user-drag: none;
            @include md{
                height: 170px;
                width: 170px;
            }



        }

        
        .sml{
            width: 50%;
          height: 100px;
         
          margin-bottom: 10px;
            -webkit-user-drag: none;
           
        }

        .col-name{
            color: rgb(0, 174, 255);
            
            font-size: 15px;
            text-align: justify;
        }

        .colu{
font-size: 13px;
color: rgba(0, 0, 0, 0.758);
        }
    }


 

 

   
    

 

   
    


  
        
  
   

  }

 





