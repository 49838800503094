.page1{
    box-shadow: 0 0px 3px 0 rgb(0, 174, 255) , 0 3px 10px 0 rgb(0, 174, 255) ;
    padding: 30px;
    border-radius: 20px;

    form{

        Button{
background-color: rgb(0, 0, 0) ;
            margin: 10px 20px;

            &:hover{
                background-color: rgb(0, 174, 255) ;
            }
        }
    }
}