@import "../../css-config/mixins.scss";

.output-code{
height: 400px;
background-color: white;
outline: none;
height: 100%;
width: 100%;

border:0.1px solid rgba(0, 0, 0, 0.37);


}