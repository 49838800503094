
  
  
  
  .certi-details-form {
  
  margin: 40px auto;
   height: fit-content;
    background: linear-gradient(to right, #ffffff 50%, rgba(0, 174, 255, 0.459) 50%);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    
  
    h1 {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }
  
    .form-group {
      margin-bottom: 20px;
    }
  
    .certi-details-info {
      margin-top: 20px;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
  
      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
  
      p {
        margin: 5px 0;
        color: #555;
      }
    }
  }


  