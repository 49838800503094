@import '../../../css-config/mixins.scss';
.step-apply {
    margin: 90px 0;

    @include cd{
        margin: 60px 0;
    }


     .heads{
       
        width: fit-content;
        padding: 10px 0;
    margin-bottom: 40px;
    
    position: relative;
   

    @include cd{
        
        font-size: 20px;
        margin-bottom: 30px;
    }

   
 

    &::after {
        content: "";
        position: absolute;
        top: 45px;

       
        left: 0;
       
        width: 30%;
        height: 3px;
        background-color: rgb(0, 174, 255);
        transition: width 0.3s ease-in-out;

      
        }

      
        

}

.step1{
    max-width: 550px;
    width: 100%;
    border: 1px solid rgba(0, 174, 255, 0.404);
    position: relative;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 25px;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);

    @include cdd{
        width: 100%;
        max-width: 100%;
    }


    @include md{

    &:hover {
        transform: scale(1.02);
      }

    }
    
   

  

    .circle1{
        height: 50px;
        
        width: 50px;
        border: 4px solid rgb(0, 174, 255);
        background-color: rgba(0, 174, 255, 0.091);
        border-radius: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;

  


    }


    .high{
        margin: 0 25px 0 15px;
        text-transform: capitalize;
        display: flex;
           gap: 3px;
           
     

        p{
            font-size: 15px;
            color: #333333e7;
           max-width: 500px;
           text-transform: none;
           width: 100%;
            letter-spacing: 0.4px;
            word-spacing: 0.4px;
           
            span{
                font-weight: bold;
                font-size: 17px;
                
            }

            @include cd{
                width: 100%;
                max-width: 250px;
            }
          
        }

    

    }

    .circle2{
        height: 50px;
        width: 50px;
      position: absolute;
  right: 10px;
        border: 4px solid rgb(0, 174, 255);
        border-radius: 50%;
        overflow: hidden;
        clip-path: inset(0 0 0 50%); 

    }
}

}