@import "../../../css-config/mixins.scss";



.review-slider{
    margin: 10px 0;
    padding: 0 10px;

    @include md{
        padding: 0 20px;
        margin: 50px 0;
    }




    &.review-ok {
        padding: 0;
        margin: 80px 0 50px 0;

        @include cd{
            margin: 60px 0;
        }


        h2 {
       
            width: fit-content;
            padding: 10px 0;
        margin-bottom: 30px;
        position: relative;
     border: none !important;

     @include cd{
        
        font-size: 20px;
    }

        &::after {
            content: "";
            position: absolute;
            top: 45px;
           
            left: 0;
           
            width: 30%;
            height: 3px;
            background-color: rgb(0, 174, 255);
            transition: width 0.3s ease-in-out;
            }

          
            

    }
        
    
        .react-multiple-carousel__arrow {
            position: absolute;
            outline: 0;
            border-radius: 6px;
            z-index: 1;
            border: 0;
            background: #ffffff;
            min-width: 40px;
            min-height: 80px;
            opacity: 1;
            cursor: pointer;
            transition: transform 0.5s, background-color 0.3s;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    
            &:hover {
                background-color: #f2f2f2;
                transform: scale(1.1);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
            }
    
            &::before {
                font-size: 40px;
                color: #00aeff;
                display: block;
                font-family: revicons;
                text-align: center;
                position: relative;
            }
        }
    
        .react-multiple-carousel__arrow--left {
            left: 0;
        }
    
        .react-multiple-carousel__arrow--right {
            right: 0;
        }
    
        .review-main {
            background-color: #ffffff;
            width: 95%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            margin: 20px auto;
            padding: 20px;
            border-radius: 12px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            @include cd{
                width: 85%;
            }
    
            &:hover {
                transform: scale(1.02);
                box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 0 8px 25px rgba(0, 0, 0, 0.15);
            }
    
            .borderb {
                height: 75px;
                width: 75px;
                border: 2px solid #00aeff;
                border-radius: 50%;
                padding: 3px;
                overflow: hidden;

                @include cd{
                    width: 100px;
                    height: 100px;
                }
    
                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    border: 1px solid #333;
                }
            }
    
            .review-desc {
                color: rgba(0, 0, 0, 0.6);
                font-size: 15px;
                line-height: 1.6;
                text-align: center;
            
                max-height: 230px;

                @include cd{
                    max-height: 220px;
                }
               
              
            }
            
    
            .review-name {
                position: absolute;
                bottom: 10px;
                right: 10px;
                padding: 2px 4px;
                background-color: #00aeff23;
                color: #000000da;
                text-shadow: none;
                font-weight: 600;
                font-size: 0.75rem;
                letter-spacing: 0.5px;
                border-radius: 8px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

                @include cd{
                    padding: 2px 8px;
                }
            }
        }
    }
    


    
    h2{
        border-bottom: 1px dashed rgb(0, 174, 255);
        width: fit-content;
        padding: 10px 0;
        margin-bottom: 30px;
    }
   

    .react-multiple-carousel__arrow {
        position: absolute;
        outline: 0;
        // transition: all .5s;
        border-radius: 0;
        z-index:1;
        border: 0;
        background: rgb(254, 254, 255);
        min-width: 70px;
        min-height: 113px;
        opacity: 1;
        cursor: pointer;
        transition: transform 0.5s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
    }
    
    .react-multiple-carousel__arrow:hover {
        background: transparent;
        transform: scale(1.3);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .react-multiple-carousel__arrow::before {
        font-size: 40px;
        color: rgb(0, 174, 255);
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative
    }

    .react-multiple-carousel__arrow--left {
        left: 0
    }
    
    
    
    .react-multiple-carousel__arrow--right {
        right: 0
    }
    


 

 

   
    

 

   
    


    
    .studentb{
        text-align: center;
        font-size: 20px;
        color: rgb(0, 174, 255);
        letter-spacing: 3px;
        margin-top: 20px;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        line-height: 40px;

        @include md{
            font-size: 29px;
        }
       
        
    }


       
    

    .review-main{
        background-color: white;
        width: 95%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        justify-content: center;
        gap: 20px;
        margin:  20px auto;
        padding: 20px 10px;
    
        align-items: center;
        flex-direction: column;
        position: relative;

        @include md{
            width: 360px;
            padding: 20px 15px;
            margin:  40px auto;
        }
    
   

  .borderb{
    
    height: 120px;
    width: 120px;
    border: 2px solid rgb(0, 174, 255);
    border-radius: 100%;
    padding: 5px;
    

    img{
        border-radius: 100%;
        height: 100%;
        width: 100%;
    border: 1px solid black;
    border-radius: 100%;
    

       
    }
  

    
  }

  .review-desc{
    // padding: 0 15px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 30px;
    white-space: wrap;
    height: 300px;
  
    @include md{
        height: 260px;
    }
    
    

  }

  .review-name{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 30px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: rgb(255, 254, 254);
    letter-spacing: 3px;

  }

}

    }







    


