@import "../../../css-config/mixins.scss";
.increment{
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    gap: 100px;
    max-height: fit-content;
    font-size: 24px;
    width: 100%;
    // border-bottom: 1px solid rgba(0,0,0,0.5);

@include ml{
    flex-flow: row;
        
}

.main-in{
    display: flex;
    align-items: center;
    gap: 20px;
    color: rgb(0, 174, 255);
   
        

   

    h2{
        font-size: 17px;
    }
    
    
    .counting{
            color: rgba(0, 0, 0, 0.685);
            
            
        }
    }
}



