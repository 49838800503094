@import '../../../css-config/mixins.scss';

.who {
    text-align: center;
    color: #333;
    margin: 90px 0 0 0;

    @include cd{
        margin: 60px 0;
    }

    h2 {
       
            width: fit-content;
            padding: 10px 0;
        margin-bottom: 30px;
        position: relative;
        border: none !important;

        @include cd{
            font-size: 20px;
        }
     

        &::after {
            content: "";
            position: absolute;
            top: 45px;
           
            left: 0;
           
            width: 30%;
            height: 3px;
            background-color: rgb(0, 174, 255);
            transition: width 0.3s ease-in-out;
            }

          
            

    }

    .free {
        
        
      
    
        .accordion__button {
            background-color: rgb(255, 255, 255);
            font-weight: 600;
            font-size: 1.3em;
            cursor: pointer;
            outline: none;
            border: none;
            letter-spacing: 1px;
            padding-left: 0;
            text-align: left;
            color: #3a86ff;
            transition: background-color 0.3s ease;
            margin-bottom: 20px;

            @include cd{
                font-size: 1.04em;
                line-height: normal;
                margin-bottom: 10px;
                
            }
    
          
    
           
        }
    
        .accordion__panel {
          
           letter-spacing: 1px;
            color: rgba(0, 0, 0, 0.7);
          
           
           text-align: left;
            background-color: #ffffff;
        }
    }
}


