@import "../../css-config/mixins.scss";


.cart-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 99999;
    


    .opac-layer {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
       

    }

    .cart-content {
        width: 100%;
        height: 100%;
        background: white;
        position: fixed;
        z-index: 1;
        display: flex;
        flex-direction: column;
        transform: translateX(100%);
        animation: slideCartWindow 0.3s ease forwards;

        @include md {
            width: 340px;

        }

        .cart-header {
            background-color:rgb(0, 174, 255);
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
            padding: 25px 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .logo {
                flex-grow: 1;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;

            }

            .close-btn {
                display: flex;
                align-items: center;
                gap: 5px;

                cursor: pointer;

                svg {
                    font-size: 18px;
                }

                .text {
                    text-transform: uppercase;
                    font-size: 14px;
                }

                &:hover {
                    opacity: 0.5;
                }


            }

        }



    }

   
       

      

        .home-content {
            font-size: 20px;

            @include md {
                display: none;
            }

            svg {
                margin-right: 5px;
            }

            a{
                text-decoration: none;
                color: black;
            }

            .route{
                padding: 14px 0px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                background-color: rgba(0,0,0,0.08);

                svg{
                    
                    margin: 0 14px;
                }
            }
        }

       
    

    .heading {
        font-size: 19px;
        padding: 14px;
        border-bottom: 3px solid rgba(0, 0, 0, 0.1);
        
        font-weight: 700;

    }

    
    .logo2 {
        background-color: rgba(0, 0, 0, 0.07);
        color: black;
        font-size: 20px;
        padding: 19px;
        display: flex;
        align-items: center;
        gap: 5px;

        svg{
            color: red;
        }
    }


}

@keyframes slideCartWindow {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }

}