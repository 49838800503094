.report-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .report-card {
      margin-top: 20px;
      width: 80%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      
      h2 {
        margin-bottom: 10px;
        font-size: 20px;
      }
  
      .download-link {
        display: inline-block;
        margin-top: 10px;
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        text-decoration: none;
        border-radius: 3px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }

        span{
            display: flex;
            align-items: center;
            gap: 5px;
        }
  
        svg {
          margin-right: 5px;
        }
      }
  
      .pdf-container {
        margin-top: 20px;
        width: 100%;
        height: 500px; /* Adjust height as needed */
        overflow: auto;
  
        embed {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  