@import "../../../../../css-config/mixins.scss";
.pagination {
 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    margin-top: 1rem;

   

  


button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    // margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: rgba(249, 249, 255, 0.705);
    color: #000000;
    border: 1px solid black;
    border-radius: 100%;
    transition: all 0.5s;

    &:hover{
        background-color: rgba(0, 174, 255, 0.653);

    }
  
   
    


&.active {
    font-weight: 900;
    background: rgba(0, 174, 255, 0.653);
    color: #101010;
    border: none;
   
}
}
}