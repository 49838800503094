@import '../../css-config/mixins.scss';
.containerlog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 53px);
  background: linear-gradient(to bottom, #ffffff 50%, rgb(0, 174, 255) 50%);
  
  

  @include md{
    background: linear-gradient(to right, #ffffff 50%, rgb(0, 174, 255) 50%);
 height: calc(100vh - 81px);
  }
.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 24rem;
  box-sizing: border-box;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #1f2937;
    margin-bottom: 1.5rem;



  }

  form{

    .input-group {
      margin-bottom: 1rem;


      
label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
}

input {
  margin-top: 0.25rem;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
}



    }

    .submit-button {
      background-color: rgb(0, 0, 0);
      color: white;
      padding: 0.5rem;
      border-radius: 0.375rem;
      border: none;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      &:hover {
        background-color: rgb(0, 174, 255);
      }
     
    }
  }
}



}