@import '../../../css-config/mixins.scss';
.module-m {
    margin: 70px 0;

    @include cd{
        margin: 50px 0;
    }


    h2 {
       
        width: fit-content;
        padding: 10px 0;
    margin-bottom: 50px;
    
    position: relative;
    border: none !important;

    @include cd{
        
        font-size: 20px;
    }

   
 

    &::after {
        content: "";
        position: absolute;
        top: 45px;

       
        left: 0;
       
        width: 30%;
        height: 3px;
        background-color: rgb(0, 174, 255);
        transition: width 0.3s ease-in-out;

      
        }

      
        

}

    .module-list {

        margin: 50px 0;

        @include cd{
display: flex;
flex-direction: column;
align-items: center;
            margin: 20px 0;

        }

        .list {
            border: 1px solid rgba(0, 174, 255, 0.313);
            /* Softer border */
            height: fit-content;
            padding: 30px 20px;
            width: 90%;
            border-radius: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            justify-content: center;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          
            gap: 15px;

            @include cdd{
                width: 100%;
            }

            .up {
                position: absolute;
                top: -12px;
                left: 30px;
                background-color: rgb(232, 239, 243);
                z-index: 1;
                color: rgb(0, 120, 220);
                /* Softer blue */
                padding: 4px 12px;
                border-radius: 20px;
                font-size: 13px;
                font-weight: bold;
               
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

                @include cd{
                    width: 80%;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
             
            }

            li {
                list-style: none;
                font-family: 'YourFontName-Black', sans-serif;
                font-weight: 700;
                font-size: 1.2rem;
                display: flex;
                align-items: flex-start;
                gap: 10px;
                color: #333;

                @include cd{
                    font-size: 1.04rem;
                }

             

                svg {
                    color: rgb(0, 120, 220);
                    font-size: 20px;
                    transform: scale(1.12);
                    

                    @include cd{
                        margin-top: 2px;
                      
                    }
                }
            }

            .mk {
                display: flex;
                flex-wrap: wrap;
                margin-left: 27px;
                column-gap: 15px;
                row-gap: 8px;

                p {
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;
                    color: rgba(0, 0, 0, 0.6);

                    svg {
                        color: rgb(0, 174, 255);
                        margin-right: 1px;
                        font-size: 16px;
                    }
                }
            }
        }

    }
}