// CodeMaster.scss
@import "../../css-config/mixins.scss";
.codemaster-main{

  padding: 12px 6px;

  @include md{
    padding: 6px;
  }

  .main-editor{

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 10px;
    background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .codeitor-name{
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 2px;
      word-spacing: 2px;
      font-family: monospace;
      line-height: 30px;

      @include md{
        font-size: 24px;
      }
    }
    

    .run-btn{
      // float: right;
      width: fit-content;
      padding: 8px 30px;
      align-self: flex-end;
      // margin: 10px 20px;
      background-color: black;
      color: rgb(255, 255, 255);
      border: none;
      border-radius: 20px;
      cursor: pointer;
    }
  }


  


  
  .code-master {
    display: flex;
   
  
    gap: 5px;
    flex-direction: column;

    @include md{
      flex-direction: row;
    }
  
    
  
    .editor-container {
      width: 100%;
      height: 50vh;
      margin-bottom: 1rem;

      @include md{
        width: 50%;
        height: calc(100vh - 143px);
        margin-bottom: 0;
      }
  
    
    }
  
    .output-container {
      width: 100%;
      height: 50vh;

      @include md{
        @media (min-width: 768px) {
          width: 50%;
          height: calc(100vh - 143px);
        }
      }
  
    
    }
  }

}
  