@import "../../css-config/mixins.scss";

.exmove{
    background-color: cornflowerblue;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    z-index: 999999;
    position: absolute;
    transform: translate(100px,200px);
    transition: all 0.5s ease-in-out;
    top: 0;
    left: 0;
}