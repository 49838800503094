@import "../../../../css-config/mixins.scss";
.sql-bar {
   
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  
   


            


            li {
               position: relative;
               display: flex;
               align-items: center;
                list-style: none;
                padding: 8px 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                pointer-events:visible;
                transition: 0.3s all;

                &:hover svg{
                    opacity: 1;
                    transform: translateX(0);
                }

                svg{
                    transition: all 0.3s ease-in;
                    position: absolute;
                    right: 20px;
                    opacity: 0;
                    transform: translateX(-20px);
                    display: none;

                    @include md{
                        display: block;
                    }
                }
                
               
                
              

               

                &:hover {
                    background-color: rgb(12, 162, 243);
                    color: rgb(255, 255, 255);
                    cursor: pointer;
                  
                    
                    


                }

            }

}



  
 
   


