@import '../../../css-config/mixins.scss';
.Main-payment {
  position: relative;
  height: fit-content;
  margin: 30px 0;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../../assets/logo/logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0.1;
    z-index: -1; /* Place the pseudo-element below the actual content */
  }

    @include payment{
      display: none;
    }
   
  
    .circle-main {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;

     
      
    
     
     
  .border{
    border: 5px dotted black;
    padding: 30px;
    border-radius: 100%;
    @include sx{
      margin-left: 23px;
      padding: 15px;
      border: 2px dotted black;
    }
    .methods {
      height: 200px;
      width: 200px;
      color: white;
      border-radius: 100%;
      background-color: rgb(0, 0, 0);
      box-shadow: 0 0 0 8px rgb(0, 174, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      @include sx{
        height: 130px;
        width: 130px;
        font-size: 14px;
      }                 
    }
  }
  
  
    
  .payment-method{
    background-color: transparent;
    position: relative;
    width: 300px;
    .cash,
    .chaque,
    .direct-deposit,
    .cards,
    .mobile-wallet {
      width: 200px;
      height: 50px;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 21px;
      padding: 20px;
      margin: 20px 0;
      @include sx{
        width: 150px;
        font-size: 20px;
      }
    }
  
    .cash {
     
      border: 5px solid rgb(0, 174, 255);
      color: rgba(0, 0, 0, 0.671);
  
      &:hover {
        scale: 1.1;
      }
    }
    .chaque {
      margin-left: 50px;
      border: 5px solid rgb(0, 174, 255);
      color: rgba(0, 0, 0, 0.671);
  
      &:hover {
        scale: 1.1;
      }
      @include sx{
        margin-left: 20px;
      }
    }
    .direct-deposit {
      margin-left: 85px;
      border: 5px solid rgb(0, 174, 255);
      color: rgba(0, 0, 0, 0.671);
      
      
      &:hover {
        scale: 1.1;
      }
      @include sx{
        margin-left: 35px;
      }
    }
    .cards {
      margin-left: 50px;
      border: 5px solid rgb(0, 174, 255);
      color: rgba(0, 0, 0, 0.671);
  
      &:hover {
        scale: 1.1;
      }
      @include sx{
        margin-left: 20px;
      }
    }
    .mobile-wallet {
      
      border: 5px solid rgb(0, 174, 255);
      color: rgba(0, 0, 0, 0.671);
  
      &:hover {
        scale: 1.1;
      }
    }
  }
    .exa {
        padding: 20px;
      margin-top: 10px;
      width: 100%;
      p{
        color: black;
      }
    }
   
   
  
  }
  }

  



 