@import "../../../css-config/mixins.scss";

.categories-slider{
    padding: 20px 6px ;
   


 

    .studentb{
        text-align: center;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.699);
        padding-bottom: 20px;
        @include md{
            padding-bottom: 40px;

        }
        
    }
   

    .banner-slide{

    
            padding: 20px 10px;
        
        z-index: 9;
       margin: auto; 
       
        
        background: transparent;
        border-top: 1px solid rgba(0, 0, 0, 0.059);
        border-bottom: 1px solid rgba(0, 0, 0, 0.087);
        // box-shadow: 0 0px 23px rgba(0, 0, 0, 0.559);
        }
   

  .borderb{
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
   
   
   

  

  
   

    .imageb{
        width: 90px;
        height: 90px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.411);
        padding: 4px;
        -webkit-user-drag: none; 
        user-select: none; 
        pointer-events: none;

        @include cd{
            width: 80px;
            height: 80px;
        }
        
        
    }

    .nameb{
        font-weight: 700;
        color: #000000bf;
        text-transform: capitalize;
        font-size: 12px;
        @include md{
            font-size: 15px;
        }
       
        
        
    }

    .positionb{
        color:rgb(0, 174, 255);
      
     
        font-size: 14px;

     
    }
  }
  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    // transition: all .5s;
    border-radius: 0;
    z-index:1;
    border: 0;
    background:white;
    min-width: 30px;
    height: 100%;
    opacity: 1;
    cursor: pointer;
    transition: transform 0.5s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-multiple-carousel__arrow:hover {
    background: transparent;
    transform: scale(1.3);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-multiple-carousel__arrow::before {
    font-size: 30px;
    color: #000000;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative
}

.react-multiple-carousel__arrow--left {
    left: 0
}



.react-multiple-carousel__arrow--right {
    right: 0
}



}

