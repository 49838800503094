@mixin sm {
    @media only screen and (min-width: 640px) {
        @content;
    }
}

@mixin m600 {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin m400 {
    @media only screen and (max-width: 400px) {
        @content;
    }
}

@mixin m350 {
    @media only screen and (max-width: 350px) {
        @content;
    }
}




@mixin md {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin cd{
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin cdd{
    @media only screen and (max-width: 1150px) {
        @content;
    }
}

@mixin cd1{
    @media only screen and (min-width: 1150px) {
        @content;
    }
}

@mixin cardmd {
    @media only screen and (max-width: 540px) {
        @content;
    }
}

@mixin cardl {
    @media only screen and (min-width: 540px) {
        @content;
    }
}

@mixin mdd {
    @media only screen and (min-width: 838px) {
        @content;
    }
}

@mixin ml {
    @media only screen and (min-width: 970px){
        @content;
    }

    
}


@mixin mll {
    @media only screen and (max-width: 970px){
        @content;
    }

    
}



@mixin lg {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin s {
    @media only screen and (min-width: 590px) {
        @content;
    }
}

@mixin me {
    @media only screen and (max-width: 768px) {
        @content;
    }
}
@mixin xxxs {
    @media only screen and (min-width: 400px) {
        @content;
    }
}

@mixin cardf {
    @media only screen and (max-width: 500px) {
        @content;
    }
}

@mixin ss {
    @media only screen and (max-width: 640px) {
        @content;
    }
}
@mixin ssss {
    @media only screen and (max-width: 340px) {
        @content;
    }
}



@mixin payment {
    @media only screen and (max-width: 375px) {
        @content;
    }
}

@mixin sx{
    @media only screen and (max-width: 592px) {
        @content;
    }
}

@mixin xxs {
    @media only screen and (max-width: 450px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

@mixin xsx{
    @media only screen and (max-width:386px){
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width: 1536px) {
        @content;
    }
}

@mixin ssms {
    @media only screen and (max-width: 1058px) {
        @content;
    }
}
