@import "../../css-config/mixins.scss";

.main-header {
    width: 100%;
    padding: 0 20px;
    background-color: white;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;

    @include md {
        padding: 0 30px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;

        align-items: center;
        height: 60px;
        // max-width: 1200px;
        margin: 0 auto;

        @include md {
            height: 80px;
        }

        .left {
            list-style-type: none;
            color: rgb(0, 0, 0);
            display: none;
            gap: 25px;
            text-decoration: none;

            @include md {
                display: flex;


            }



            .icon-part {
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                flex-direction: column;
                transition: all ease-in;
                animation: 0.2s;


                @include md {
                    &:hover {
                        transform: scale(0.9);
                        color: rgba(0, 0, 0, 0.833);
                    }
                }


            }

            li {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;

            }

            ;



        }

        .center {

            height: auto;
            width: 130px;
            cursor: pointer;

            img {
                height: 100%;
                width: 100%;
            }

            @include md {
                width: 180px;
                height: auto;

                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            @include ssss {
                width: 90px;
                height: auto;
            }

        }

        .right {
            display: flex;
            align-items: center;
            gap: 17px;

            @include md {
                gap: 25px
            }


            li {
                display: flex;
                align-items: center;
                border: 1px dashed rgba(0, 0, 0, 0.671);
                padding: 8px;
                color: #333;
                border-radius: 8px;

                cursor: pointer;
                gap: 3px;

                @include cd {
                    padding: 5px 8px;
                }

                svg {
                    margin-top: 3px;
                }
            }



            .animated-button {
                position: relative;
                display: flex;
                align-items: center;
                
                gap: 4px;
                padding: 10px 12px;
                border: 1px solid;
               
                font-size: 16px;
                background-color: rgb(0, 174, 255);
                border-radius: 12px;
                font-weight: 600;
                color: rgb(255, 255, 255);
                border: 1px solid rgb(0, 174, 255);
                cursor: pointer;
                overflow: hidden;
                transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
                display: none;

                &:hover{
                    background-color: white;
                     border: 1px solid rgba(0, 0, 0, 0.205);
                    color: #333;
                }

                @include md {
                    display: block;
                }

  


             
            }


















            svg {
                transform: scale(1.05);
                cursor: pointer;
                font-weight: 700;
                color: rgb(0, 0, 0);
                transition: all ease-in;
                animation: 0.2s;

                @include md {
                    transform: scale(1.2);

                    &:hover {
                        transform: scale(1.23);
                        color: rgba(2, 2, 2, 0.799);
                    }
                }
            }


        }
    }

    a {
        text-decoration: none;
        color: white;
    }

    &.sticky-header {
        position: sticky;
        top: 0;

        animation: stickyHeader 0.3s ease forwards;
        width: 100%;
        padding: 0 20px;
        background-color: rgb(255, 255, 255);

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        z-index: 99;

        .left {
            list-style-type: none;
            color: grey;
            display: none;
            gap: 25px;
            text-decoration: none;

            @include md {
                display: flex;


            }



            .icon-part {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                flex-direction: column;
                transition: all ease-in;
                animation: 0.2s;
                color: rgb(23, 23, 23);

                @include md {
                    &:hover {
                        transform: scale(0.9);
                        color: rgb(0, 0, 0);
                    }
                }


            }

            li {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;

            }

            ;



        }

        .right {
            display: flex;
            align-items: center;
            gap: 17px;

            .stage {

                display: none;

            }

            @include md {
                gap: 25px
            }

            svg {
                transform: scale(1.05);
                cursor: pointer;
                font-weight: 700;
                color: rgb(21, 21, 21);
                transition: all ease-in;
                animation: 0.2s;

                @include md {
                    transform: scale(1.2);

                    &:hover {
                        transform: scale(1.23);
                        color: rgb(2, 2, 2);
                    }
                }
            }

            .cart-icon {
                position: relative;

                span {
                    min-width: 20px;
                    text-align: center;
                    background-color: #8e2de2;
                    padding: 2.5px;
                    position: absolute;
                    top: -5px;
                    right: -12px;
                    font-size: 12px;
                    line-height: 1;
                    border-radius: 10px;
                }
            }

        }



    }

    &.white-header {
        background-color: rgb(0, 174, 255);
        border: none;

        .header-content {
            display: flex;
            justify-content: space-between;

            align-items: center;
            height: 60px;
            // max-width: 1200px;
            margin: 0 auto;

            @include md {
                height: 80px;
            }

            .left {
                list-style-type: none;
                color: rgb(255, 255, 255);
                display: none;
                gap: 25px;
                text-decoration: none;

                @include md {
                    display: flex;


                }



                .icon-part {
                    color: rgb(255, 255, 255);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    flex-direction: column;
                    transition: all ease-in;
                    animation: 0.2s;


                    @include md {
                        &:hover {
                            transform: scale(0.9);
                            color: rgba(255, 255, 255, 0.833);
                        }
                    }


                }

                li {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;

                }

                ;



            }

            .center {

                height: auto;
                width: 130px;
                cursor: pointer;

                img {
                    height: 100%;
                    width: 100%;
                }

                @include md {
                    width: 180px;
                    height: auto;

                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @include ssss {
                    width: 90px;
                    height: auto;
                }

            }

            .right {
                display: flex;
                align-items: center;
                gap: 17px;

                @include md {
                    gap: 25px
                }

                .pol{
position: relative;

                li {
                    display: flex;
                    align-items: center;
                    border: 1px solid rgba(255, 255, 255, 0.753);
                    padding: 7px;
                    background-color: rgba(255, 255, 255, 0.107);
                    border-radius: 8px;
                    color: rgb(255, 255, 255);
                    font-weight: bold;
                letter-spacing: normal;

                    cursor: pointer;
                    gap: 3px;



                    svg {
                        margin-top: 3px;
                      
                    }
                }

            }



                .animated-button {
                    position: relative;
                    display: flex;
                    align-items: center;

                    gap: 4px;
                    padding: 8px;
                    border: 1px solid;
                    border-color: transparent;
                    font-size: 16px;
                    background-color: inherit;
                    border-radius: 100px;
                    font-weight: 600;
                    color: rgb(255, 255, 255);
                    box-shadow: 0 0 0 2px rgb(255, 255, 255);
                    cursor: pointer;
                    overflow: hidden;
                    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
                    display: none;

                    @include md {
                        display: block;
                    }

                    & .circle {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        background-color: rgb(255, 255, 255);
                        color: white;
                        border-radius: 50%;
                        opacity: 0;
                        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
                    }

                    & .text {
                        position: relative;
                        z-index: 1;
                        border: 1ox solid rgba(255, 255, 255, 0.502);
                        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
                    }

                    &:hover {
                        box-shadow: 0 0 0 12px transparent;
                        color: #000000;
                        border-radius: 12px;
                    }

                    &:active {
                        scale: 0.95;
                        box-shadow: 0 0 0 4px rgb(255, 255, 255);
                    }

                    &:hover .circle {
                        width: 220px;
                        height: 220px;
                        opacity: 1;
                    }
                }


















                svg {
                    transform: scale(1.05);
                    cursor: pointer;
                    font-weight: 700;
                    color: rgb(254, 254, 254);
                    transition: all ease-in;
                    animation: 0.2s;

                    @include md {
                        transform: scale(1.2);

                        &:hover {
                            transform: scale(1.23);
                            color: rgba(255, 255, 255, 0.799);
                        }
                    }
                }


            }
        }

    }
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-80px);
    }

    100% {
        transform: translateY(0);
    }
}