@import '../../../../../css-config/mixins.scss';

.about-section-u{
    background: linear-gradient(to right, rgba(0, 174, 255, 0.397), rgb(0, 174, 255), rgba(0, 174, 255, 0.502));
    padding: 4rem 10px;
    color: white;
    text-align: center;
    margin: 20px auto;
  
    .content-wrapper {
      max-width: 1120px;
      margin: 0 auto;
     
  
      h2 {
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 40px;

        @include md{
            font-size: 2.5rem;   
        }
      }
  
      p {
        margin-top: 1rem;
        font-size: 1.125rem;
      }
    }
  
    .details-section {
      margin: 4rem auto;
      width: 90%;
      background: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      padding: 1.25rem;
      border-radius: 0.5rem;
  
      .details-header {
        text-align: center;
  
        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          color: #2d3748;
        }
  
        p {
          margin-top: 0.5rem;
          font-size: 0.875rem;
          color: #718096;
        }
      }
  
      
  
        .details-header {
          padding: 1.25rem 0;
          border-top: 1px solid #e2e8f0;
  
          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #2d3748;
          }
  
          ul {
            margin-top: 1rem;
            list-style: none;
            padding: 0;
  
            li {
              margin-bottom: 0.5rem;
              font-size: 0.875rem;
              color: #718096;
  
              span {
                color: #2d3748;
                font-weight: 600;
              }
            }
          }
        }
      
    }
  }
  