@import "../../../css-config/mixins.scss";
.report-main{
  margin: 40px 10px;
  background-color: aliceblue;
  box-shadow: 0px 0px 1px black;
  width: auto;
  padding: 20px 10px;



  @include md{
    width: 70%;
    margin: 40px auto;
  }


  
 
  
  
  
  .react-tabs__tab--selected {

    border-color: #aaa;
    color: rgb(0, 174, 255);
 
    border-radius: 5px 5px 0 0;
  }
  
 
  

  
  

  



  
.content-on{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: wrap;
  margin: 40px 0;
  gap: 30px;
.report-content{
  height: 180px;
  transform: skewY(-5deg);
  width: 250px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 2px black;
  display: flex;
  // justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover p{
    display: none;
  }

 

 &:hover .overlay{
width: 100%;
height: 100%;
  


 }

 .overlay{
 width: 100%;
 height: 50%;
 display: flex;
 background-image: linear-gradient(to right, rgb(201, 207, 210), rgba(255, 255, 255, 0.42));
      
    flex-direction: column;
       justify-content: center;
 align-items: center;

 text-align: center;
 padding: 12px;
 color: rgba(0,0,0,0.6);
  transition: all 0.3s;

  &:hover p{
    display: block;
  }

  p{
    display: none;
  }

  h1{
    font-size:21px;
    text-transform: capitalize;
  }
 }



}

}

  
}