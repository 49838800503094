@import '../../../css-config/mixins.scss';

.intern-main {
    font-size: 2.5rem;
    font-weight: bolder;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
   text-align: center;
  background-color: rgb(0, 174, 255);
    
    padding: 15px;
    text-align: center;
    
    letter-spacing: 2px;
   
   
    color: white;

    

    @include cd{
        font-size: 1.5rem;
        height: 100px;
        line-height: normal;
    }


  

 
}
