@import "../../../../css-config/mixins.scss";

.office{
    
    text-align: center;
    line-height: 30px;



    h1{
        font-size:x-large;
      line-height: normal;
      padding: 30px 0;
      color: rgb(255, 255, 255);
      background-color: rgb(0, 174, 255);
    }


    .office-card{
        margin: 40px 0;
        display: flex;
        justify-content: center;
        gap: 50px;
        flex-flow: wrap;

        .office-card-first{
            height: fit-content;
color: black;
border: 1px solid rgba(0, 174, 255, 0.313);
box-shadow: 0 0px 3px 0 rgb(0, 174, 255) , 0 3px 10px 0 rgb(0, 174, 255) ;



        
            width: 300px;
            padding: 10px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;

            h3{
                background-color:rgb(0, 174, 255) ;
                width: 100%;
                border-radius: 12px;
                color: white;
            }

            p{
                word-spacing: 2px;
            }
        }
    }
}