.page3{
    box-shadow: 0 0px 3px 0 rgb(0, 174, 255) , 0 3px 10px 0 rgb(0, 174, 255) ;
    padding: 30px;
    color: rgb(81, 80, 80);
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}