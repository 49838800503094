.service-page {
    background-color: #f7fafc;
      max-width: 1140px;
      margin: 0 auto;
      padding: 2rem 1rem;
  
      h1 {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
      }
  
      .grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
  
        @media (min-width: 640px) {
          grid-template-columns: repeat(2, 1fr);
        }
  
        @media (min-width: 1024px) {
          grid-template-columns: repeat(3, 1fr);
        }
  
        .service-card {
          background-color: #e2e8f0;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
          padding: 1.5rem;
          border-radius: 0.5rem;
  
          h2 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }
  
          p {
            color: #4a5568;
            margin-bottom: 1rem;
          }
  
          &.bg-blue-200 {
            background-color: #bfdbfe;
          }
  
          &.bg-green-200 {
            background-color: #bbf7d0;
          }
  
          &.bg-yellow-200 {
            background-color: #fef08a;
          }
  
          &.bg-purple-200 {
            background-color: #e9d5ff;
          }
  
          &.bg-red-200 {
            background-color: #fecaca;
          }
        }
      }
    
  }
  