@import "../../../css-config/mixins.scss";

 
    

   
  
    .hero-container {

      color: white;
      text-align: center;
      padding: 2rem 0;
     width: 97%;
      margin: 20px auto;
      gap: 10px;
      display: flex;
   
      flex-direction: column;
      align-items: center;

      @include md{
        flex-direction: row-reverse;
        margin: 40px auto;
        width: 90%;
      }


      .contact-main {
  
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;

        @include md{
        width: 60%;
        }
      
        .pentagon-border {
          position: absolute;
          opacity: 0;
      
          transform: scale(0);
         
          animation-timing-function: ease-in-out;

          @include cd{
            margin-top: 220px;
          }

        
        }
      
        /* Outer Pentagon */
        .outer-pentagon {
          width: 250px;
          height: 250px;
          animation: pentagonAnim 6s infinite;
          animation-delay: 0s;

          @include cd{
            width: 200px;
          height: 200px;
          }
        }
      
        /* Inner Pentagon 1 */
        .inner-pentagon {
          width: 200px;
          height: 200px;
          animation: pentagonAnim 6s infinite;
          animation-delay: 1s;

          @include cd{
            width: 150px;
          height: 150px;
          }
        }
      
        /* Inner Pentagon 2 */
        .inner1-pentagon {
          width: 150px;
          height: 150px;
          animation: pentagonAnim 6s infinite;
          animation-delay: 2s;

          @include cd{
            width: 100px;
          height: 100px;
          }
        }
      
        /* Inner Pentagon 3 */
        .inner2-pentagon {
          width: 100px;
          height: 100px;
          animation: pentagonAnim 6s infinite;
          animation-delay: 3s;

          @include cd{
            width: 50px;
          height: 50px;
          }
        }
      
        /* Inner Pentagon 4 */
        .inner3-pentagon {
          width: 50px;
          height: 50px;
          animation: pentagonAnim 6s infinite;
          animation-delay: 4s;

          @include cd{
            width: 25px;
          height: 25px;
          }
        }
      }
      
      /* Keyframes for animation */
      @keyframes pentagonAnim {
        0% {
          opacity: 0;
          transform: scale(0);
        }
        50% {
          opacity: 1;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(1.5);
        }
      }
  
     

      .hero-content{
       align-self: flex-start;
  width: 100%;

       @include md{
        width: 40%;
       }
  
      
      


        .hero-title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
          line-height: 50px;
          letter-spacing: 2px;
          text-transform: uppercase;

          @include md{
            font-size: 2.5rem;
            line-height: 60px;
          }
  
          span{
              color: rgb(0, 174, 255);
        
          }
        }
    
       
    
        .hero-buttons {
          display: flex;
          flex-flow: wrap;
         row-gap: 10px;
        
          justify-content: center;
    
          .hero-button {
            z-index: 9;
            background-color: rgb(0, 174, 255);
            color: rgb(255, 255, 255);
            font-weight: bold;
            padding: 1rem 2rem;
            margin: 0 0.5rem;
            border-radius: 25px;
          //   border: 1px solid white;
            text-transform: uppercase;
            cursor: pointer;
            animation: changeColor 3s infinite;
            transition: background-color 0.8s;
    
            &:nth-child(2) {
              background-color: white;
              color: #333;
              border: 1px solid rgb(0, 174, 255);
          
    
              &:hover {
                border: 1px solid white;
                color: rgb(255, 255, 255);
                background-color: rgb(0, 174, 255);
              }
            }

            &:nth-child(1) {
              background-color: white;
              color: #333;
              border: 1px solid rgb(0, 174, 255);
              animation: none;
          
    
              &:hover {
                border: 1px solid white;
                color: rgb(255, 255, 255);
                background-color: rgb(0, 174, 255);
              }
            }
    
            &:hover {
             
              animation: none;
            }
          }
        }

      }
  
      
    }
  

    
    @keyframes changeColor {
      0% {
        background-color: rgb(255, 255, 255);
        color: black;
      }
    
      100% {
        background-color: rgb(0, 174, 255);
        color: rgb(255, 255, 255);
      }
    }
  